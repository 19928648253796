/* jshint esversion: 6 */

import * as THREE from '../../node_modules/three/build/three.module.js'
import {
  GLTFLoader
} from '../../node_modules/three/examples/jsm/loaders/GLTFLoader.js'
import {
  OrbitControls
} from '../../node_modules/three/examples/jsm/controls/OrbitControls.js'

import Stats from '../../node_modules/three/examples/jsm/libs/stats.module.js'

import {
  RoughnessMipmapper
} from '../../node_modules/three/examples/jsm/utils/RoughnessMipmapper.js'

import {
  RGBELoader
} from '../../node_modules/three/examples/jsm/loaders/RGBELoader.js'

/// /////////////////////////
//
// keep your hands off everything above
//
// Setup model below
//
/// /////////////////////////

/* model name & path */
const modelname = '3dmodels/reference_1_model/Masster_08_glft_ohne_Texturen.gltf'
const bgname = '3dmodels/reference_1_model/Hohlkehle.glb'

/* light & shadow setup */
const useLights = false
let useShadows = true

/* background setup: color names or hex codes (0xff0000) -> red */
const backgroundColor = '#004A4F'

/* hdr / environment setup */
const environmentName = 'img/hdr/reference1_environment.hdr'
const useEnvironment = true
const showEnvironment = false
const exposure = 0.2

/* debug helper */
// let logModelChildNames = true;
const useStats = false

/// /////////////////////////
//
// Setup model above
//
// keep your hands off everything that follows below
//
/// /////////////////////////

let scene, camera, renderer, mixer, clock, pmremGenerator, controls, stats

let canvas, container
let routeActivated = false

const hotspotObjects = []
const hotspotLastStateObjects = []

let modelLoaded = false
const menuOffset = 76

// let orbitChanged = true;

function setUpRender () {
  canvas = document.querySelector('#canvas-reference-1')
  container = document.querySelector('#container-reference-1')
  canvas.width = container.offsetWidth
  canvas.height = container.offsetHeight - menuOffset

  renderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    alpha: true
  })

  renderer.autoClear = false

  if (useShadows) {
    renderer.shadowMap.enabled = true
    // renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  }
  renderer.setSize(canvas.width, canvas.height)

  pmremGenerator = new THREE.PMREMGenerator(renderer)
  pmremGenerator.compileEquirectangularShader()

  renderer.toneMapping = THREE.ReinhardToneMapping
  renderer.toneMappingExposure = exposure

  renderer.outputEncoding = THREE.sRGBEncoding

  if (useStats) {
    stats = new Stats()
    container.appendChild(stats.dom)
  }
};

function setUpCamera () {
  const fov = 60
  const aspect = canvas.width / canvas.height
  const near = 0.1
  const far = 20

  camera = new THREE.PerspectiveCamera(fov, aspect, near, far)
  camera.position.z = 2
}

function setUpThreeScene () {
  scene = new THREE.Scene()
  scene.background = new THREE.Color(backgroundColor)
  // const color = 0xff0000;
  // const density = 0.1;
  // scene.fog = new THREE.FogExp2(color, density);
  scene.add(camera)
  // scene.add(textureCamera);
};

function setUpLight () {
  const hlight = new THREE.AmbientLight(0x404040, 100)
  scene.add(hlight)

  const directionalLight = new THREE.DirectionalLight(0xffffff, 100)
  directionalLight.position.set(0, 1, 0)
  directionalLight.castShadow = true
  scene.add(directionalLight)

  const light = new THREE.PointLight(0xc4c4c4, 10)
  light.position.set(0, 300, 500)
  scene.add(light)

  const light2 = new THREE.PointLight(0xc4c4c4, 10)
  light2.position.set(500, 100, 0)
  scene.add(light2)

  const light3 = new THREE.PointLight(0xc4c4c4, 10)
  light3.position.set(0, 100, -500)
  scene.add(light3)

  const light4 = new THREE.PointLight(0xc4c4c4, 10)
  light4.position.set(-500, 300, 500)
  scene.add(light4)
};

function setUpControls () {
  // const controls = new OrbitControls(camera, renderer.domElement);
  controls = new OrbitControls(camera, canvas)

  controls.enableZoom = true // Zooming
  controls.minDistance = 1
  controls.maxDistance = 2
  controls.enablePan = false

  // controls.target.set(10000000, 100, 10000000);
  // controls.update();

  controls.autoRotate = true // enable rotation
  controls.maxPolarAngle = Math.PI / 2 // Limit angle of visibility
  // controls.addEventListener('change', onOrbitChanged);

  var autorotateTimeout
  // stop autorotate after the first interaction
  controls.addEventListener('start', function () {
    clearTimeout(autorotateTimeout)
    controls.autoRotate = false
  })

  // restart autorotate after the last interaction & an idle time has passed
  controls.addEventListener('end', function () {
    autorotateTimeout = setTimeout(function () {
      controls.autoRotate = true
    }, 10000)
  })

  return controls
}

function setUpEnvironment () {
  new RGBELoader()
    .setDataType(THREE.UnsignedByteType)
    .load(environmentName, function (texture) {
      var envMap = pmremGenerator.fromEquirectangular(texture).texture

      if (showEnvironment) {
        scene.background = envMap
      }
      if (useEnvironment) {
        scene.environment = envMap
      }

      texture.dispose()
      pmremGenerator.dispose()
    })
}

function addModelToScene () {
  modelLoaded = false
  let model
  const loader = new GLTFLoader()

  // A reusable function to set up the models. We're passing in a position parameter
  // so that they can be individually placed around the scene
  const onLoad = (gltf, position, _color) => {
    // console.log('started model loading');

    // use of RoughnessMipmapper is optional
    var roughnessMipmapper = new RoughnessMipmapper(renderer)

    model = gltf.scene // .children[0];

    model.scale.set(1, 1, 1)
    model.position.copy(position)

    model.traverse(function (child) {
      // shadows
      if (child.isMesh) {
        roughnessMipmapper.generateMipmaps(child.material)

        if (useShadows) {
          if (child.name.includes('##receive')) {
            child.receiveShadow = true
            // console.log("RECEIVE - " + child.name);
          }
          if (child.name.includes('##cast')) {
            child.castShadow = true
            // console.log("CAST - " + child.name);
          }
        }
      } else if (child.isLight) {
        if (useShadows) {
          child.castShadow = true
        }
      }
      // hotspots
      if (child.name.includes('##hotspot-')) {
        const splits = child.name.split('##')
        const hotspot = {}
        const lastStateHotspot = {}

        splits.forEach(split => {
          // console.log("SPLIT - " + split);
          if (split.includes('hotspot-')) {
            const secSplits = split.split('-')
            if (secSplits.length > 1) {
              hotspot.id = secSplits[1]
              lastStateHotspot.id = hotspot.id
            }
          }
        })

        hotspot.name = splits[0]
        hotspot.object = child
        lastStateHotspot.x = hotspot.object.position.x
        lastStateHotspot.y = hotspot.object.position.y
        lastStateHotspot.z = hotspot.object.position.z

        hotspotObjects.push(hotspot)
        hotspotLastStateObjects.push(lastStateHotspot)
        // console.log(hotspot.object.position);
      }
      if (_color && child.material) {
        // console.log(child)
        // console.log("original", child.material)
        child.material.color.set(_color)
      }
      // if (logModelChildNames) {
      // console.log(child.name);
      // }
    })

    scene.add(model)
    roughnessMipmapper.dispose()

    mixer = new THREE.AnimationMixer(model)
    // gltf.animations.forEach((clip) => {
    //     mixer.clipAction(clip).play();
    // });

    modelLoaded = true
    // console.log('finished model loading');

    window.addEventListener(
      'toggleOpenAnimation',
      (e) => {
        if (modelLoaded) {
          // console.log(e);

          gltf.animations.forEach((clip) => {
            var action = mixer.clipAction(clip)
            action.setLoop(THREE.LoopOnce)

            if (e.detail) {
              action.reset()
              action.timeScale = 1
              action.clampWhenFinished = true
              action.play()
            } else {
              action.paused = false
              action.timeScale = -1
              action.play()
            }
          })
        }
      },
      false
    )

    const event = new Event('ref1_modelLoaded')

    window.dispatchEvent(event)

    hotspotObjects.forEach((hotspot) => {
      HotspotPositionChanged(hotspot)
    })
  }

  // the loader will report the loading progress to this function
  const onProgress = (progress) => {
    // console.log(progress.loaded / progress.total)

    const event = new CustomEvent('ref1_loadingprogress', {
      detail: progress.loaded / progress.total
    })

    window.dispatchEvent(event)
  }

  // the loader will send any error messages to this function, and we'll log
  // them to to console
  const onError = (errorMessage) => {
    console.log(errorMessage)
  }

  // load the first model. Each model is loaded asynchronously,
  // so don't make any assumption about which one will finish loading first
  const modelPosition = new THREE.Vector3(0, 0, 0)

  loader.load(modelname, gltf => onLoad(gltf, modelPosition), onProgress, onError)
  loader.load(bgname, gltf => onLoad(gltf, modelPosition, '#004a4f'), onProgress, onError)
  // if (hotspotObjects.length > 0) {
  // hotspotObjects.forEach((hotspot) => {
  //     HotspotPositionChanged(hotspot);

  // })
  // }

  return model
};

function onWindowResize () {
  canvas.width = container.offsetWidth
  canvas.height = container.offsetHeight - menuOffset

  camera.aspect = canvas.width / canvas.height

  camera.updateProjectionMatrix()

  renderer.setSize(canvas.width, canvas.height)
}

function onRouteActivated () {
  if (controls) {
    controls.reset()
  }
  routeActivated = true
  // console.log('ref 1 activated');
  requestAnimationFrame(render)
  onWindowResize()
}

function onRouteDeactivated () {
  routeActivated = false
  // console.log('ref 1 deactivated');
}

// function onOrbitChanged(){
//     orbitChanged = true;
// }

function HotspotPositionChanged (hotspot) {
  // TODO calc screenspace position
  const screenPosition = toScreenPosition(hotspot.object, camera)

  const event = new CustomEvent('ref1_hotspotPositionChanged', {
    detail: {
      id: hotspot.id,
      x: screenPosition.x,
      y: screenPosition.y + menuOffset / 2
    }
  })

  window.dispatchEvent(event)
}

function toScreenPosition (obj, camera) {
  var vector = new THREE.Vector3()

  var widthHalf = 0.5 * canvas.width
  var heightHalf = 0.5 * canvas.height

  obj.updateMatrixWorld()
  camera.updateProjectionMatrix()
  vector.setFromMatrixPosition(obj.matrixWorld)
  vector.project(camera)

  vector.x = (vector.x * widthHalf) + widthHalf
  vector.y = -(vector.y * heightHalf) + heightHalf

  return {
    x: vector.x,
    y: vector.y
  }
};

function render (time) {
  time *= 0.001 // convert time to seconds
  // console.log(time);
  var delta = clock.getDelta()

  if (mixer) mixer.update(delta)

  renderer.render(scene, camera)
  if (useStats) {
    stats.update()
  }
  if (modelLoaded) {
    hotspotObjects.forEach((hotspot) => {
      hotspotLastStateObjects.forEach((lastStateHotspot) => {
        if (hotspot.id === lastStateHotspot.id) {
          HotspotPositionChanged(hotspot)

          lastStateHotspot.x = hotspot.object.position.x
          lastStateHotspot.y = hotspot.object.position.y
          lastStateHotspot.z = hotspot.object.position.z
        }
      })
    })
  }

  // orbitChanged = false;
  controls.update()
  if (routeActivated) {
    requestAnimationFrame(render)
  }
};

export async function main (_isMobile) {
  clock = new THREE.Clock()

  // console.log("in scene ", _isMobile)
  useShadows = !_isMobile
  setUpRender()

  setUpCamera()

  setUpThreeScene()

  if (useLights) {
    setUpLight()
  }
  setUpControls(camera, renderer)

  if (useEnvironment || showEnvironment) {
    setUpEnvironment()
  }

  window.addEventListener('resize', onWindowResize, false)
  window.addEventListener('Ref1_routeActivated', onRouteActivated, false)
  window.addEventListener('Ref1_routeDeactivated', onRouteDeactivated, false)
  await addModelToScene()

  // requestAnimationFrame(render)
}
