<template>
  <div>
    <div id="container-reference-1">
      <canvas id="canvas-reference-1"></canvas>
    </div>
    <div id="container-reference-1-overlay">
      <div id="ref1-menu-flex" class="left-top">
        <div class="flex-column">
          <div class="flex-row">
            <b-button
              variant="primary"
              id="back"
              class="rounded-circle px-2"
              @click="routerGoBack()"
            >
              <b-icon icon="chevron-left" scale="1"></b-icon>
            </b-button>
            <div id="upper-hints1">
              <div class="bringtofront flex-row tolow">
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 304 91"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xml:space="preserve"
                  xmlns:serif="http://www.serif.com/"
                  style="
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    stroke-linejoin: round;
                    stroke-miterlimit: 2;
                  "
                >
                  <path
                    d="M80.35,65.806c40.24,-9.212 91.24,-15.35 137.233,-3.255c18.742,4.929 44.365,12.51 60.221,24.706c6.957,5.351 16.951,4.047 22.303,-2.911c5.351,-6.958 4.047,-16.951 -2.911,-22.303c-18.917,-14.55 -49.162,-24.374 -71.523,-30.255c-52.148,-13.713 -110.116,-6.923 -155.193,3.654c-1.379,-12.401 0.323,-24.876 5.745,-35.442l-76.225,74.65l105.455,16.19c-10.434,-5.354 -19.007,-14.335 -25.105,-25.034Z"
                    style="fill: #fff"
                  />
                </svg>
                <p class="handwriting">{{ hints.hint1 }}</p>
              </div>
            </div>
          </div>
          <div class="flex-row">
            <b-button
              variant="primary"
              id="fullscreen"
              class="rounded-circle px-2"
              @click="toggleFullscreen()"
            >
              <b-icon
                v-if="!fullscreenState"
                icon="fullscreen"
                scale="1"
              ></b-icon>
              <b-icon
                v-if="fullscreenState"
                icon="fullscreen-exit"
                scale="1"
              ></b-icon>
            </b-button>
            <div id="upper-hints2">
              <div class="bringtofront flex-row tolow">
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 304 91"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xml:space="preserve"
                  xmlns:serif="http://www.serif.com/"
                  style="
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    stroke-linejoin: round;
                    stroke-miterlimit: 2;
                  "
                >
                  <path
                    d="M80.35,65.806c40.24,-9.212 91.24,-15.35 137.233,-3.255c18.742,4.929 44.365,12.51 60.221,24.706c6.957,5.351 16.951,4.047 22.303,-2.911c5.351,-6.958 4.047,-16.951 -2.911,-22.303c-18.917,-14.55 -49.162,-24.374 -71.523,-30.255c-52.148,-13.713 -110.116,-6.923 -155.193,3.654c-1.379,-12.401 0.323,-24.876 5.745,-35.442l-76.225,74.65l105.455,16.19c-10.434,-5.354 -19.007,-14.335 -25.105,-25.034Z"
                    style="fill: #fff"
                  />
                </svg>
                <p class="handwriting">{{ hints.hint2 }}</p>
              </div>
            </div>
          </div>
          <img src="img/360_icon.webp" class="icon360" alt="" />
        </div>
      </div>
      <div class="flex-column left-bottom">
        <div id="lower-hints">
          <div class="bringtofront flex-row2 tolow">
            <svg
              class="align-bottom"
              width="40px"
              height="40px"
              viewBox="0 0 321 425"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xml:space="preserve"
              xmlns:serif="http://www.serif.com/"
              style="
                fill-rule: evenodd;
                clip-rule: evenodd;
                stroke-linejoin: round;
                stroke-miterlimit: 2;
              "
            >
              <path
                d="M31.615,344.829c-12.166,-2.734 -23.395,-8.401 -31.615,-16.958l45.783,96.368l49.624,-94.447c-8.474,8.14 -19.785,13.329 -31.918,15.606c9.308,-107.952 51.382,-295.397 242.143,-313.66c8.738,-0.837 15.153,-8.61 14.316,-17.348c-0.836,-8.738 -8.61,-15.153 -17.348,-14.317c-211.536,20.253 -261.102,226.266 -270.985,344.756Z"
                style="fill: #fff"
              />
            </svg>
            <div class="flex-column">
              <div class="flex-row2 tolow2">
                <svg
                  class="align-bottom"
                  width="60px"
                  height="60px"
                  viewBox="0 0 600 1300"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xml:space="preserve"
                  xmlns:serif="http://www.serif.com/"
                  style="
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    stroke-linejoin: round;
                    stroke-miterlimit: 2;
                  "
                >
                  <path
                    d="M625.431,12.242c-80.072,-13.075 -235.322,-12.19 -371.292,96.032c-139.745,111.226 -259.916,336.632 -253.924,780.253c0.068,5.076 4.245,9.141 9.32,9.072c5.076,-0.068 9.141,-4.245 9.073,-9.32c-5.875,-434.935 109.977,-656.565 246.986,-765.613c129.826,-103.332 277.942,-104.836 355.29,-92.527c1.012,10.856 8.448,20.543 19.523,23.878c14.581,4.39 29.984,-3.883 34.375,-18.464c4.391,-14.582 -3.883,-29.985 -18.464,-34.376c-11.887,-3.579 -24.32,1.257 -30.887,11.065Z"
                    style="fill: #fff"
                  />
                </svg>
                <p class="handwriting-small" v-html="hints.hint4">
                  <br /><br />
                </p>
              </div>
              <p class="handwriting">
                {{ hints.hint3 }}<br /><br />
                <br />
              </p>
            </div>
          </div>
        </div>
        <b-button
          id="animationToggle"
          variant="primary"
          @click="toggleAnimation"
          class="hidden fixed-width"
          >{{ openLabel }}</b-button
        >
      </div>

      <!-- Hotspots-->
      <b-button
        v-for="content in modalContent"
        :key="content.id"
        variant="primary"
        v-b-modal.modal-1
        v-show="animateOpen"
        :id="content.buttonid"
        class="rounded-circle px-2 hidden"
        @click="setModalContent(content.id)"
      >
        <b-icon icon="plus" scale="2"></b-icon>
      </b-button>

      <!-- Modal -->
      <b-modal
        id="modal-1"
        centered
        scrollable
        :hide-footer="true"
        :title="currentModalContent.title"
      >
        <template #modal-header="{ close }">
          <h2>{{ currentModalContent.title }}</h2>
          <!-- Emulate built in modal header close button action -->
          <b-button
            class="rounded-circle px-2"
            variant="primary"
            @click="close()"
          >
            <b-icon icon="x" scale="2"></b-icon>
          </b-button>
        </template>
        <h5>{{ currentModalContent.headline }}</h5>
        <b-card-img
          :src="currentModalContent.image"
          alt="Image"
          class="rounded-top"
        ></b-card-img>
        <br /><br />
        <p class="white-text" v-html="currentModalContent.copytext"></p>
      </b-modal>

      <!-- Backdrop -->
      <div id="backdrop"></div>

      <!-- Loading Screen -->
      <ref-loading-screen
        class="loadingscreen-container"
        :image="loadingScreen.image"
        :progresslabel="loadingScreen.progresslabel"
        :progressvalue="loadingScreen.progressvalue"
        :progressmax="loadingScreen.progressmax"
        :headline="loadingScreen.headline"
        :subline="loadingScreen.subline"
        :mobileHint="loadingScreen.mobileHint"
        :ismobile="isMobile"
        :referenceid="loadingScreen.referenceid"
        :loadingcompleted="loadingScreen.loadingcompleted"
      ></ref-loading-screen>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import store from '../store'
import RefLoadingScreen from '../components/Ref_LoadingScreen.vue'

export default {
  name: 'Reference1',
  data () {
    return {
      fullscreenState: false,
      color: '#00ffd6',
      loading: true,
      animateOpen: false,
      activated: false,
      openLabel: '',
      openLabelOpen: '',
      openLabelClose: '',
      script: '',
      modalContent: [],
      isMobile: false,
      currentModalContent: {
        title: '',
        headline: '',
        image: '',
        copytext: ''
      },
      loadingScreen: {
        image: '',
        progresslabel: '',
        progressvalue: 0,
        progressmax: 1,
        headline: '',
        subline: '',
        mobileHint: '',
        referenceid: '',
        loadingcompleted: false
      },
      hints: {
        hint1: '',
        hint2: '',
        hint3: '',
        hint4: ''
      }
    }
  },
  created () {
    // console.log('ref1 created')
    // this.$root.$on('bv::modal::show', this.onShowModal)
    // this.$root.$on('bv::modal::hidden', this.onHiddenModal)
  },
  mounted () {
    // console.log("ref1 mounted");

    window.addEventListener(
      'ref1_loadingprogress',
      (e) => {
        this.loadingScreen.progressvalue = e.detail

        if (e.detail === 1) {
          var that = this
          setTimeout(function () {
            that.loadingScreen.loadingcompleted = true
          }, 1000)
        }
      },
      false
    )
    window.addEventListener(
      'ref1_modelLoaded',
      (e) => {
        // console.log("MODEL LOADED" + e);
        document.getElementById('animationToggle').classList.remove('hidden')
        // document.getElementById("pulseLoader").classList.add("hidden");

        document.getElementById('Hotspot01').classList.remove('hidden')
        document.getElementById('Hotspot02').classList.remove('hidden')
        this.loading = false
      },
      false
    )
    window.addEventListener(
      'ref1_hotspotPositionChanged',
      (e) => {
        // console.log("HOTSPOT " + e.detail.id + " CHANGED - x: " + e.detail.x + ", y: " + e.detail.y );
        if (this.activated) {
          if (e.detail.id === '01') {
            document.getElementById('Hotspot01').style.top = e.detail.y + 'px'
            document.getElementById('Hotspot01').style.left = e.detail.x + 'px'
          }
          if (e.detail.id === '02') {
            document.getElementById('Hotspot02').style.top = e.detail.y + 'px'
            document.getElementById('Hotspot02').style.left = e.detail.x + 'px'
          }
        }
      },
      false
    )
    window.addEventListener(
      'ref1_LoadingScreenClosed',
      (e) => {
        if (this.activated) {
          document.getElementById('backdrop').classList.add('hidden')
          document.getElementById('upper-hints1').classList.add('hidden')
          document.getElementById('upper-hints2').classList.add('hidden')
          document.getElementById('lower-hints').classList.add('hidden')
        }
      },
      false
    )
    this.changeData(require('@/data/content_reference1.json'))

    // var isMobile = false; //initiate as false
    // // device detection
    // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
    //     isMobile = true;
    // }

    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4)) ||
    navigator.userAgent.toLowerCase().match(/mobile/i)) {
      // console.log("MOBIL")
      this.isMobile = true
    } else {
      // console.log("DESKTOP")
      this.isMobile = false
    }
    this.script = require('@/scripts/reference1_3dcontent.js')
    this.script.main(this.isMobile)
  },
  activated () {
    // console.log('ref1 activated')
    this.activated = true
    const that = this
    const event = new Event('Ref1_routeActivated')
    window.dispatchEvent(event)

    this.keepalive = this.script
    // console.log(this.script)

    // setTimeout(function () {
    //   if (that.activated) {
    //     document.getElementById('hint').classList.add('fadeout')
    //   }
    // }, 7000)
    // setTimeout(function () {
    //   if (that.activated) {
    //     document.getElementById('hint-progress').classList.add('scaledown')
    //   }
    // }, 2000)

    this.setAnimationState(false)
  },
  deactivated () {
    // console.log('ref1 deactivated')
    this.activated = false
    this.animateOpen = false
    this.setAnimationState(this.animateOpen)

    const event = new Event('Ref1_routeDeactivated')
    window.dispatchEvent(event)
    // console.log(this.script)

    this.keepalive = this.script
  },
  components: {
    RefLoadingScreen
  },
  methods: {
    toggleFullscreen () {
      this.fullscreenState = !this.fullscreenState

      if (this.fullscreenState) {
        document.documentElement.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    },
    routerGoBack () {
      if (this.fullscreenState) {
        this.toggleFullscreen()
      }
      // this.$router.go(-1);
      this.$router.push({ path: '/#' + store.state.activeHomeViewHash })
    },
    setModalContent (id) {
      this.currentModalContent = this.modalContent[id]
    },
    toggleAnimation () {
      this.animateOpen = !this.animateOpen
      this.setAnimationState(this.animateOpen)
    },
    setAnimationState (state) {
      const event = new CustomEvent('toggleOpenAnimation', {
        detail: state
      })

      window.dispatchEvent(event)

      this.openLabel = state ? this.openLabelClose : this.openLabelOpen
    },
    changeData: function (data) {
      this.openLabelOpen = data.openLabelOpen
      this.openLabelClose = data.openLabelClose

      this.loadingScreen.image = data.loadingScreen.image
      this.loadingScreen.progresslabel = data.loadingScreen.progresslabel
      this.loadingScreen.headline = data.loadingScreen.headline
      this.loadingScreen.subline = data.loadingScreen.subline
      this.loadingScreen.mobileHint = data.loadingScreen.mobileHint
      this.loadingScreen.referenceid = data.loadingScreen.referenceid

      this.hints = data.hints

      for (let i = 0; i < data.modalContent.length; i++) {
        const item = data.modalContent[i]
        item.id = i
        this.modalContent.push(item)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loadingscreen-container {
  position: absolute;

  top: 50%;

  left: 50%;

  -moz-transform: translateX(-50%) translateY(-50%);

  -webkit-transform: translateX(-50%) translateY(-50%);

  transform: translateX(-50%) translateY(-50%);
}
#backdrop {
  width: 100%;
  height: 100%;
  background-color: #000000bb;
  z-index: 25;
  position: relative;
  pointer-events: all;
}
#ref1-menu-flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.flex-column2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-row2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 40px;
}
.align-bottom {
  align-self: flex-end;
}
.bringtofront {
  text-align: start;
  z-index: 30;
  position: relative;

  font-family: "Handwriting";

  svg {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.icon360 {
  opacity: 0.5;
  // position: absolute;
  // top: 150px;
  // left: 34px;
  width: 50px;
  height: auto;
  position: relative;
  left: -7px;
  margin-top: 5px;
  margin-bottom: 5px;
}
#container-reference-1 {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
}
#container-reference-1-overlay {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  pointer-events: none;
}
#canvas-reference-1 {
  top: 76px;
  left: 0px;
  position: absolute;
}
#animationToggle {
  pointer-events: auto;
}
#Hotspot01,
#Hotspot02 {
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: auto;
}
#fullscreen,
#back {
  pointer-events: auto;
}
#fullscreen {
  margin-top: 10px;
}
#pulseLoader {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 25;
}
.hidden {
  display: none;
}
.left-bottom {
  position: absolute;
  bottom: 40px;
  left: 40px;
}
.left-top {
  position: absolute;
  top: 100px;
  left: 40px;
  // z-index: 25;
}
.fixed-width {
  width: 120px !important;
}
.white-text {
  color: white;
  font-size: 1rem;
}
.btn-primary {
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:hover {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00977e77;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
@media (pointer: coarse) {
  #hint {
    display: block;
  }
}

.handwriting {
  color: white;
  font-size: 3rem;
  line-height: 0.2;
}
.handwriting-small {
  color: white;
  font-size: 2rem;
  line-height: 0.7;
}
@media (max-width: 768px) {
  .handwriting {
    font-size: 2rem !important;
    font-size: calc(
      2rem + (2.4 - 2) * ((100vw - 20rem) / (48 - 20))
    ) !important;
    line-height: calc(
      0.5 * (0.5rem + (1.2 - 0.5) * ((100vw - 20rem) / (48 - 20)))
    ) !important;
  }
  .handwriting-small {
    font-size: 1.6rem !important;
    font-size: calc(
      1.6rem + (1.7 - 1.6) * ((100vw - 20rem) / (48 - 20))
    ) !important;
    line-height: calc(
      1.4 * (1.4rem + (1.2 - 1.4) * ((100vw - 20rem) / (48 - 20)))
    ) !important;
  }
}
.tolow {
  display: flex;
}
@media (max-height: 800px) {
  .tolow {
    display: none;
  }
}
.tolow2 {
  display: flex;
}
@media (max-height: 850px) {
  .tolow2 {
    display: none;
  }
}
</style>
